<template>
  <div>
    <div>
      <header></header>
    </div>
    <div class="top_usermsgs">
      <!--  v-if="is_avatar==0" -->
      <!-- <div class="need_ava" @click="showOption = true">
        点此更换用户头像，立刻获得<b>500</b>{{ zlb_name }}。
      </div> -->
      <van-action-sheet
        v-model:show="showOption"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @cancel="onCancel"
        @select="onSelect"
      />
      <!-- <vue-upload-component ref="upload" :url="uploadUrl" :headers="uploadHeaders" :options="uploadOptions" @input="handleInput" @before-upload="beforeUpload" @uploading="uploading"></vue-upload-component> -->
      <div class="top_usermsg" :style="is_vip==1 ? 'background: #7E6B5A':'background: #2A292E'">
        <p class="set_up" @click="set_up()">设置</p>
        <img class="set_tu" src="../../static/images/my_index/set_up.png" alt @click="set_up()">
        <div class="div_img_ava">
          <img
            v-show="vip_level ==2 || vip_level == 4 "
            class="vip_icon"
            :src="vip_level==2
              ? require('../../static/images/my_index/mine_ic_crowon.png')
              :require('../../static/images/my_index/mine_ic_evip_crown.png')"
            alt
          >
          <div class="user_head" :style="vip_level==2 ? 'border: #ebc28b 2px solid':'border: #fcffff 2px solid'">
            <img
              class="user_head_img"
              onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'"
              :src="user_head"
              alt
            >
          </div>
        </div>

        <div class="top_center">
          <p class="username">{{ username }} &nbsp;&nbsp;</p>
          <div v-if="user_grade" class="username_span">{{ user_grade }}</div>
          <img
            v-show="is_vip !=0 "
            class="vip_small_icon"
            :src="is_vip==1
              ? require('../../static/images/my_index/mine_ic_vip.png')
              :require('../../static/images/my_index/mine_ic_evip_sign.png')"
            alt
          >
          <p class="huise">
            荣誉分
            <span>{{ ryf?ryf:0 }}</span>
            <span v-if="uid" class="click_btn" @click="showUrl()">升级攻略</span>
          </p>
          <p class="huise">
            筑龙币
            <span>{{ zlb?zlb:0 }}</span>
            <span v-if="uid" class="click_btn" @click="goMore()">兑换礼物</span>
          </p>
          <!-- <p class="huise">
            本周学习时长
            <span v-if="Math.floor(study_hour/60)">{{ Math.floor(study_hour/60) }}</span><span v-if="Math.floor(study_hour/60)">小时</span>
            <span v-if="study_hour%60">{{ study_hour%60 }}</span><span v-if="study_hour%60">分钟</span>
            <span v-if="study_hour===0">{{ study_hour }}</span><span v-if="study_hour===0">分钟</span>
          </p> -->
          <p class="huise">
            发帖数
            <span>{{ fts?fts:0 }}个</span>
            <span v-if="uid" class="click_btn" @click="show_my_home()">我的主页</span>
          </p>
        </div>
        <div
          v-show="is_show_vip && canShowMp"
          class="vips"
          :style="is_vip==1 ? 'background: #C29F73':'background: #7E6B5A'"
          @click="go_vip()"
        >
          <img
            class="vip_img"
            :src="is_vip==1
              ? require('../../static/images/my_index/vip_icon.png')
              :require('../../static/images/my_index/vip_img.png')"
            alt
          >
          <span class="shengji" :style="is_vip==1 ? 'border: 1px solid #D3B48B':'background: border: 1px solid #A38F7B'">{{ is_vip==1 ? '点击续费':'点击升级' }}</span>
        </div>
      </div>
      <!-- <div class="arc"> -->

      <!-- </div> -->
    </div>
    <div class="lists">
      <dl v-for="(item, index) in lists" :key="index" class="lists_dl" @click="go_detail(item)">
        <dt>
          <img :src="item.thumb" alt>
        </dt>
        <dd>{{ item.name }}</dd>
      </dl>
    </div>
    <!-- 我的课程、社区、资料 -->
    <div class="geduan"></div>
    <div v-if="menuLists.length > 0" class="midl_box">
      <div v-for="(item, cindex) in menuLists" :key="cindex" class="midl_item_box">
        <div class="my_curriculum">
          <p class="my_curriculum_border"></p>
          <h3 class="my_curriculum_text">
            {{ item.name }}
          </h3>
        </div>
        <!-- 子菜单 -->
        <div class="curriculum_list">
          <dl
            v-for="(child, childindex) in item.children"
            id="my_class"
            :key="childindex"
            class="curriculum_lists_dl"
            @click="go_detail(child)"
          >
            <dt class="item_img_box">
              <img :src="child.thumb" alt>
            </dt>
            <dd>{{ child.name }}</dd>
          </dl>
        </div>

        <div v-if="cindex == 0 && uid" class="te_quan_box">
          <div class="my_curriculum">
            <p class="my_curriculum_border"></p>
            <h3 class="my_curriculum_text">我的特权</h3>
          </div>
          <div class="info_list_box">
            <div v-if="add_img_url && add_img_url.length > 0" class="one_item">
              <img class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/7/173607jaydnxll1fzh7kvo.png" alt="">
              <div class="mid_box">
                <p class="title">我的专属客服</p>
                <p class="detail">请添加您的专属客服</p>
              </div>
              <div class="right_menu">
                <span class="red_btn" @click="addTeacher">去添加</span>
              </div>
            </div>
            <div class="one_item">
              <img v-if="give_vip_num > -1" class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/59/175759j6vkhzfkackbcc7l.png" alt="">
              <img v-else class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/10/175810869plzpzkhfechrr.png" alt="">
              <div class="mid_box">
                <p class="title">会员赠送特权
                  <span v-if="give_vip_num > -1" class="red_tip">{{ give_vip_num }}张待使用</span>
                  <span v-else class="red_tip">开通VIP会员解锁</span>
                </p>
                <p class="detail">赠送好友【筑龙VIP会员(1个月)】使用权</p>
              </div>
              <div class="right_menu">
                <span v-if="give_vip_num > 0 && (send_friend_code && send_friend_code.length > 0)" class="red_btn" @click="sendFriend">送朋友</span>
                <span v-else class="gray_btn">送朋友</span>
              </div>
            </div>
            <div class="one_item" @click="showVipLessons">
              <img v-if="give_vip_num > -1" class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/29/175829ma2szsrjaqfgvqwm.png" alt="">
              <img v-else class="left_icon" src="https://newoss.zhulong.com/forum/202412/30/21/175821cbufnql10i94gqis.png" alt="">
              <div class="mid_box">
                <p class="title">精品课赠送特权
                  <span v-if="give_vip_num > -1" class="red_tip">{{ give_lesson_num }}张待使用</span>
                  <span v-else class="red_tip">开通VIP会员解锁</span>
                </p>
                <p class="detail">VIP精品课简介页，点击右上角「请朋友看」</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 礼物 -->
    <div class="my_curriculum">
      <p class="my_curriculum_border"></p>
      <h3 class="my_curriculum_text">
        <span style></span>礼品兑换
      </h3>
      <p v-if="canShowMp" class="taskCenter" @click="taskCenter">
        任务中心
      </p>
      <p class="moreLp" @click="goMore()">
        更多礼品
        <!-- <span class="rightARRow">></span> -->
      </p>
      <!-- <img class="LpImg" src="" alt=""> -->
    </div>
    <div class="curriculum_listDh">
      <div
        v-for="(item,index) in giftlist"
        :key="index"
        class="listDHimg"
        @click="go_detailDh(item.id,index)"
      >
        <img class="LpImg" :src="item.img" alt>
      </div>
    </div>
    <!-- 收藏的专题 -->
    <div v-if="topicList.length>0 && canShowMp" class="my_curriculum">
      <p class="my_curriculum_border"></p>
      <h3 class="my_curriculum_text">
        <span style></span>收藏的专题
      </h3>
      <!-- <p class="taskCenter" @click="taskCenter">
        任务中心
      </p> -->
      <p class="allTopic" @click="goMoreTopic()">
        <span>全部收藏 ＞</span>
      </p>
    </div>
    <div v-if="topicList.length>0 && canShowMp" class="topic_box">
      <div class="main_box">
        <div v-for="(item,index ) in topicList" :key="index" class="topic_item">
          <a :href="item.url"><img class="thumb" :src="item.banner" alt=""></a>
          <div class="collect">
            {{ getNum(item.collect_num) }}
            <span class="text">收藏</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加专属客服弹窗 -->
    <van-popup v-model="showAddPop" :close-on-click-overlay="false">
      <div class="popup_body">
        <van-icon class="close_icon" name="cross" @click="showAddPop = false" />
        <div class="top_box">
          <p class="tip1">恭喜您升级为</p>
          <p class="tip2">【{{ grouptitle }}】</p>
          <p class="tip3">您可以添加筑龙学社教学总监的微信，有问题/有建议您可以直接反馈。</p>
        </div>
        <div class="bot_box">
          <img class="qrcode" :src="add_img_url" alt="">
          <p class="bot_tip">扫码添加微信</p>
        </div>
      </div>
    </van-popup>
    <!-- 送朋友会员弹窗 -->
    <van-popup v-model="showFriendPop" :close-on-click-overlay="false">
      <div class="popup_body">
        <van-icon class="close_icon" name="cross" @click="showFriendPop = false" />
        <div class="bot_box">
          <img class="qrcode" src="https://newoss.zhulong.com/forum/202412/30/58/182158zkuoakoydly3bjnr.png" alt="">
          <p class="bot_tip">微信扫码分享</p>
        </div>
      </div>
    </van-popup>

    <div class="foot">
      <p>欢迎垂询: 400-900-8066</p>
      <!-- <p>在线联系客服</p> -->
      <!-- <a href="https://work.weixin.qq.com/kfid/kfc788315514fde103c" target="_blank">在线联系客服</a> -->
    </div>
    <common></common>
  </div>
</template>

<script src="https://html2canvas.hertzen.com/dist/html2canvas.min.js"></script>
<script>
import { get_user_msg, get_gift, getVipCardCollection, getRow } from '@/api/my_user.js'
import common from '@/compontens/homeBottom.vue'
import { getAppCommonStatis } from '@/api/hometop.js'
import { canShowForMp, getMenuList } from '@/api/my_user.js'
import { getLoginUserInfo,getMiniUrlLink } from '@/api/login.js'
import tool from '@/utils/tools.js'
import WechatState from '@/utils/isWechatState.js'
import wx from 'weixin-js-sdk'
import Cookies from 'js-cookie'

// import { Toast } from 'vant'
export default {
  components: {
    common
  },
  data() {
    return {
      is_show_vip: false,
      is_vip: '',
      vip_level: '', // 2 vip ; 4 e_vip
      user_head: '', // 头像url
      zlb: '', // 筑龙币
      zlb_name: '',
      username: '', // 用户名
      user_grade: '', // 用户等级
      ryf: '', // 荣誉分
      // xxsc: '', // 学习时长
      study_hour: 0, // 学习时长
      fts: '', // 发帖数
      lists: [],
      menuLists: [],
      uid: '',
      pay_vip: '',
      pay_bbs: '',
      giftlist: [],
      // 收藏专题
      topicList: [],
      actions: [
        { name: '拍照' },
        { name: '从相册选择' }
      ],
      showOption: false,
      imageSrc: '',
      canShowMp: false,
      // 专属客服弹窗
      showAddPop: false,
      grouptitle: '', // 等级名
      add_img_url: '', // 专属客服二维码链接
      // 送朋友会员弹窗
      showFriendPop: false,
      send_friend_code: '', // 邀请好友的邀请码
      give_lesson_num: 0, // 可以送课程数量
      give_vip_num: -1 // 可以送vip的数量，-1未开通或过期
    }
  },
  mounted() {
    this.getAllConfig()
    // console.log(111)
    this.getGift()
    this.get_msg()
    this.get_login_msg()
    this.getOpenShareVipUrl()
    this.getAppCommonStatis()
    this.getVipCardCollectionFun()
    // this.islogin()
    this.getScoreName()
  },
  methods: {
    // 是否能够显示课程、直播
    getAllConfig() {
      canShowForMp().then(res => {
          console.log(res)
          const show = res.result || 0
          this.canShowMp = (show == 1)
        }).then(res => {
          // 在此请求数据
          this.getTopMenus()
          this.getMiddleMenus()
        })
      // // iOS 不用请求 其他的请求接口
      // if (tool.isIosWxMiniprogram()) {
      //   // iOS小程序 设为false，不展示
      //   this.canShowMp = false
      //   // 在此请求数据
      //   this.getTopMenus()
      //   this.getMiddleMenus()
      // } else {
      //   // 其他请求接口
      //   canShowForMp().then(res => {
      //     console.log(res)
      //     const show = res.result || 0
      //     this.canShowMp = (show == 1)
      //   }).then(res => {
      //     // 在此请求数据
      //     this.getTopMenus()
      //     this.getMiddleMenus()
      //   })
      // }
    },
    // 获取菜单和金刚区菜单
    getTopMenus() {
      // type 固定值，后台判断用:1：PC，2：H5，3：APP
      // position 菜单位置：5 金刚区、 3 列表
      getMenuList({ position: 5, type: 2 }).then(res => {
        this.lists = res.result || []
      })
    },
    getMiddleMenus() {
      // type 固定值，后台判断用:1：PC，2：H5，3：APP
      // position 菜单位置：5 金刚区、 3 列表
      getMenuList({ position: 3, type: 2 }).then(res => {
        const lists = res.result || []
        this.menuLists = []
        if (this.canShowMp) {
          this.menuLists = lists
        } else {
          let new_list = lists
          new_list.forEach(e => {
            if (e.name == '我的课程') {
              // 移除待付款
              // e.children = e.children ? e.children.filter(e => e.name != '待付款') : []
              e.name = '我的订单'
              var chilid = e.children || []
              var chilid = chilid.filter(e2 => e2.name != '课程收藏')
              chilid.forEach(e1 => {
                if (e1.name == '已购课程') {
                  e1.name = '已购订单'
                }
              });
              e.children = chilid
              this.menuLists.push(e)
            } else {
              if (e.name != '我的资料' && e.name != '我的社区') {
                this.menuLists.push(e)
              }
            }
          });
        }
      })
    },
    //
    onSelect(item) {
      if (item.name == '拍照') {
        console.log('拍照')
        // const image = this.$refs.webcam.captureImage()
        // this.imageSrc = image.src
        this.takePhoto()
      } else {
        console.log('从相册选择')
        this.chooseFromAlbum()
      }
    },
    chooseFromAlbum() {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.addEventListener('change', (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          this.user_head = e.target.result
        }
        reader.readAsDataURL(file)
      })
      input.click()
    },
    takePhoto() {
      // 创建一个隐藏的 video 元素用于播放摄像头视频
      const video = document.createElement('video');
      video.autoplay = true;
      video.style.display = 'none';
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          video.srcObject = stream;

          // 当视频准备就绪后，使用 html2canvas 截取视频帧
          video.addEventListener('playing', () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // 将截取的帧转为 base64 格式
            const imageDataUrl = canvas.toDataURL('image/jpeg');

            this.user_head = imageDataUrl;

            // 停止视频播放和摄像头使用
            video.pause();
            stream.getTracks().forEach((track) => track.stop());
          });
        })
        .catch((error) => {
          console.error('无法访问摄像头：', error);
        });
    },
    onCancel() {
      this.showOption = false
    },
    getScoreName() {
      const p = {
        type: '1'
      }
      getRow(p).then(res => {
        if (res.result && res.result.name) {
          this.zlb_name = res.result.name
        }
      })
    },
    // 我收藏的专题
    getVipCardCollectionFun() {
      var info = {
        limit: 4
      }
      getVipCardCollection(info).then((res) => {
        if (res.errNo == 0) {
          this.topicList = res.result.list
        }
      })
    },
    // 更多收藏专题
    goMoreTopic() {
      this.$router.push({ path: '/collecttopic' })
    },
    // 任务中心
    taskCenter() {
      this.$router.push({ path: '/taskcenter' })
    },
    // 更多礼品
    goMore() {
      this.$router.push({
        path: '/gift'
      })
    },
    // 礼品兑换详情
    go_detailDh(id, index) {
      console.log(id)
      this.$router.push({
        path: '/gift/giftExchange',
        query: {
          id: id
        }
      })
    },
    // 礼品兑换列表
    getGift() {
      var params = {
        page: 1,
        limit: 15
      }
      get_gift(params).then(res => {
        this.giftlist = (res.result.zlProduct.slice(0, 4))
      })
    },

    // 升级攻略
    showUrl() {
      const url = 'https://m.zhulong.com/bbs/d/32362219.html'
      window.location.href = url
    },
    // 我的主页
    show_my_home() {
      const url = `https://m.zhulong.com/bbs/ucenter?uid=${this.uid}`
      window.location.href = url
    },
    // 添加我的专属客服
    addTeacher() {
      this.showAddPop = true
    },
    // 送朋友会员弹窗
    sendFriend() {
      // this.showFriendPop = true
      // 打开小程序新页面
      if (WechatState.isWechatMiniprogram) {
        // 小程序
        const url =  `/pages/sendVIP/sendVIP`
        wx.miniProgram.redirectTo({
          url: url
        })
      } else {
        window.location.href = this.send_friend_code
      }
    },
    // 进入vip课程列表页面
    showVipLessons() {
      console.log('进入vip课程列表页面')
      const url = `${window.location.origin}/vip/`
      window.location.href = url
    },
    // 判断用户是否登录
    // islogin(){

    // },
    // 获取用户信息
    get_msg() {
      // console.log(111)
      get_user_msg().then(res => {
        console.log(res)
        // debugger
        if (res.errNo !== 0) {
          // this.$router.push('/login')
        } else if (res.errNo === 0) {
          const user_msg = res.result.user
          this.user_grade = user_msg.honour_title
          this.username = user_msg.username
          this.uid = user_msg.uid
          this.ryf = user_msg.honour_score
          this.fts = res.result.thread
          this.zlb = user_msg.score
          this.study_hour = res.result.hours
          this.user_head = user_msg.avatar
          this.is_vip = user_msg.is_vip
          this.vip_level = user_msg.vip_level
          this.pay_vip = res.result.pay_vip
          this.pay_bbs = res.result.pay_bbs
          this.is_show_vip = true
        }
      })
    },
    // 获取登录信息：特权
    get_login_msg(){
      getLoginUserInfo().then(res => {
        if (res.errNo == 0) {
          const result = res.result || {}
          const give_lesson_num = result.give_lesson_num || 0
          const give_vip_num = result.give_vip_num || 0
          this.grouptitle = result.grouptitle || ''
          this.add_img_url = result.img_url || ''
          this.give_lesson_num = parseInt(give_lesson_num)
          this.give_vip_num = parseInt(give_vip_num)
        }
      })
    },
    // 获取分享vip特权的，打开小程序对应分享页面链接
    getOpenShareVipUrl() {
      // share_uid=8588314&share_name=惜月老师
      const query = {
        'path': '/pages/sendVIP/sendVIP',
        'env_version': 'release',
        'query': '', // 先不传当前登录者信息
        'auth_app_id': 'wx828b87bfa158b387',
        'type': 1
      }
      getMiniUrlLink(query).then(res => {
        if (res.errNo == 0) {
          const code_url = res.result || ''
          this.send_friend_code = code_url
        }
      })
    },
    // 点击VIP
    go_vip() {
      window.location.href = this.pay_vip
    },
    // 点击e会员
    go_bbs() {
      if (this.pay_bbs) {
        window.location.href = this.pay_bbs
      }
    },
    // 设置
    set_up() {
      console.log(1)
      this.$router.push({
        path: '/set_up'
      })
    },
    // 菜单点击
    go_detail(item) {
      if (!this.canShowMp && WechatState.isWechatMiniprogram) {
        if (!Cookies.get('uid')) {
          // 去微信登录
          wx.miniProgram.navigateTo({
            url: `/pages/login/login?share=${encodeURIComponent(window.location.href)}`
          })
          return
        }
        // 审核小程序里
        if (item.name == '已购订单' || item.name == '待付款') {
          var index = item.name == '已购订单' ? '0' : '1'
          var url = `/pages/orderList/orderList?menuIndex=${index}`
          wx.miniProgram.navigateTo({
            url: url
          })
          return
        }
      }
      window.location.href = item.url
    },
    // 三方统计
    getAppCommonStatis() {
      getAppCommonStatis().then(res => {
        console.log(res, 'res')
        this.statis = res.result
        // var bd_script = document.createElement('script')
        // bd_script.type = 'text/javascript'
        // bd_script.innerHTML = this.statis.baidu_h5
        // console.log(this.statis, 'this.statis')
        // document.head.appendChild(bd_script)
        var cc_script = document.createElement('script')
        cc_script.type = 'text/javascript'
        cc_script.innerHTML = this.statis.circle
        document.head.appendChild(cc_script)
        var cz_script = document.createElement('script')
        cz_script.type = 'text/javascript'
        cz_script.src = 'https://s4.cnzz.com/z_stat.php?id=5078536&web_id=5078536'
        // cz_script.innerHTML = this.statis.cnzz
        document.head.appendChild(cz_script)
      })
    },
    getNum(num) {
      if (num < 1000) {
        return num
      } else if (num >= 1000 && num < 10000) {
        return parseInt((num / 1000 * 10)) / 10 + 'k'
      } else if (num >= 10000) {
        return parseInt((num / 10000 * 10)) / 10 + 'w'
      }
    }
  }
}
</script>

<style lang="scss" scoped >
@keyframes aa {
  from {
    top: 300px;
  }
  to {
    top: 285px;
  }
}
.rightARRow {
  color: #999;
  position: relative;
  left: 10px;
  // top: 4px;
  top: 1px;
}
.LpImg {
  width: 144px;
  height: 144px;
  background: #98989a;
  margin-top: 15px;
}
.moreLp {
  font-family: PingFangSC-Regular;
  position: absolute;
  right: 20px;
  top: 24px;
  color: #ee2e2e;
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 20px;
  border: 1px solid #ee2e2e;
}
.taskCenter {
  position: absolute;
  right: 160px;
  font-family: PingFangSC-Regular;
  top: 24px;
  color: #ee2e2e;
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 20px;
  border: 1px solid #ee2e2e;
}
.curriculum_listDh {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: space-around;
  border-bottom: 20px solid #f5f5f5;
}
.vip_img {
  width: 141px;
  height: 38px;
  position: absolute;
  left: 50px;
  top: 13px;
}
// #my_class:last-child {
//   visibility: hidden;
// }
.vips {
  width: 230px;
  height: 184px;
  position: absolute;
  bottom: -30px;
  right: -10px;
  border-radius: 20px;
  transform: rotate(-15deg);
  animation: aa 0.7s infinite;
  -webkit-animation: aa 0.7s infinite;
  animation-direction: alternate;
  -webkit-animation-direction: alternate;
  // z-index: 999;
}
.shengji {
  color: #f3e2ce;
  font-weight: 300;
  // text-align: center;
  display: inline-block;
  margin: 58px 0 0 55px;
  padding: 2px 20px 1px 20px;
  border-radius: 30px;
  transform: scale(0.8);
}
// .vips:after{
//   height: 80px;
//   width: 100%;
//     background: #7b6b5c;
//     border-radius:50%;
//   position: absolute;
//   content:'';
//     left: 0;
//     bottom: -10px;
//     z-index: -1;
// }
.top_usermsgs {
  min-height: 419px;
  width: 100%;
  position: relative;
  // z-index: -2;
}
// .top_usermsg:after{
//   height: 102px;
//   width: 100%;
//     background: #2a292e;
//     border-radius:50%;
//   position: absolute;
//   content:'';
//     left: 0;
//     bottom: -51px;
//     z-index: -1;

// }
.top_usermsg {
  width: 100%;
  height: 419px;
  // overflow: hidden;
  position: relative;
  border-radius: 0 0 50% 50% / 0 0 52px 52px;
  // transform: rotate(-20deg);
  // position: relative;
  // width: 250px;
  // height: 100px;
  // margin-left: 106px;
  overflow: hidden;
}
.arc {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  z-index: -1;
  content: "";
  height: 102px;
  width: 100%;
  border-radius: 50%;
  // background-image: linear-gradient(to right, #f6aa52 0%, #e9594e 100%);
  background: #2a292e;
}
.div_img_ava{
   width: 155px;
   position: relative;
  .user_head {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    margin: 136px 0 0 30px;
    float: left;
    overflow: hidden;
  }
  .vip_icon {
    position: absolute;
    // margin: 120px 0 0 30px;
    left:30px;
    top:120px;
    width: 42px;
    height: 42px;
  }
  .user_head_img {
    width: 155px;
    height: 155px;
  }
}
.vip_small_icon {
  position: absolute;
  width: 34px;
  height: 34px;
  margin-left: 20px;
  margin-top: 8px;
}
.username {
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  margin-right: 20px;
  display: inline-block;
}
.top_center {
  margin: 140px 0 0 30px;
  float: left;
  line-height: 50px;
}
.username_span {
  display: inline-block;
  background: #caaa7b;
  color: #312d12;
  height: 40px;
  border-radius: 30px;
  padding: 0 15px 5px 15px;
  // display: inline-block;
  // margin-left: 15px;
}
.huise {
  color: #bbbbbb;
  font-weight: 600;
}
.huise span {
  color: #C5AB81;
  padding-left: 10px;
}
.huise .click_btn {
  margin-left: 15px;
}
.set_up {
  color: #fefdff;
  font-size: 34px;
  position: absolute;
  right: 77px;
  top: 68px;
}
.set_tu {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 17px;
  top: 70px;
}
.lists {
  margin: 57px 0 50px 0;
  display: flex;
  justify-content: space-around;
}
.lists_dl {
  width: 100px;
  float: left;
}
.lists_dl dt img {
  width: 76px;
  height: 76px;
  margin-left: 12px;
}
.lists_dl dd {
  text-align: center;
  color: #3c3c3c;
}
.geduan {
  width: 100%;
  height: 20px;
  background: #f5f5f5;
}
#my_curriculum {
  position: relative;
}
.my_curriculum {
  position: relative;
  height: 88px;
  width: 100%;
  border-bottom: 2px solid #f5f5f5;
}
.my_curriculum_text {
  line-height: 88px;
  color: #303030;
  padding: 2px 0 0 20px;
  margin-left: 30px;
  font-size: 30px;
  font-weight: 800;
  // float: left;
}
.my_curriculum_border {
  float: left;
  display: inline-block;
  width: 5px;
  height: 34px;
  background: #db403b;
  margin: 28px 0 0 30px;
}
.curriculum_list {
  width: calc(100% - 50px);
  padding: 0 25px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 20px solid #f5f5f5;
}
.curriculum_lists_dl {
  min-width: 105px;
  margin: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.curriculum_lists_dl dt img {
  width: 40px;
  height: 40px;
}
.curriculum_lists_dl dd {
  margin-top: 5px;
  text-align: center;
  color: #3c3c3c;
}
#my_ziliao:last-child {
  visibility: hidden;
}
// #my_ziliao:nth-child(4) {
//   visibility: hidden;
// }
.more {
  position: absolute;
  right: 75px;
  top: 30px;
  font-weight: 300;
  color: #aaa9a9;
}
.foot {
  text-align: center;
  line-height: 50px;
  padding-top: 20px;
  padding-bottom: 120px;
  color: #999;
}
.allTopic {
  position: absolute;
  right: 50px;
  font-family: PingFangSC-Regular;
  top: 24px;
  // color: #ee2e2e;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  // border-radius: 20px;
  // border: 1px solid #ee2e2e;
  i{
    font-size:24px;
  }
}
.topic_box{
  width: 100%;
  display: flex;
  overflow-x: scroll;
}
.main_box{
  padding: 28px;
  // display: flex;
  // flex-wrap: wrap;
  // width: 1842px;
  flex-shrink:0;
  height: 189px;
  .topic_item{
    width: 443px;
    height: 189px;
    margin-right: 23px;
    // margin-bottom: 30px;
    position:relative;
    float: left;
    .thumb{
      width: 100%;
      height: 100%;
    }
    .collect{
      position:absolute;
      bottom:0;
      right: 0;
      background: rgba(51,51,51,0.90);
      border-radius: 12px 0px 0px 0px;
      color:#fff;
      padding: 4px 20px;
      font-size: 24px;
      .text{
        margin-left: 4px;
      }
    }
    &:nth-last-child(1){
      margin-right: 0;
    }
  }
}
.need_ava{
  height: 67px;
  line-height: 67px;
  background-color: rgba(187, 160, 121, 1);
  text-align: center;
  color:#EED3AB;
  font-size: 24px;
}
.te_quan_box {
  border-bottom: 20px solid #f5f5f5;
  .info_list_box {
    padding: 30px 30px 5px;

    .one_item {
      font-family: PingFangSC-Regular;
      padding: 20px;
      margin-bottom: 25px;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #F9F9F9;
      .left_icon {
        margin-right: 20px;
        width: 60px;
        display: block;
      }
      .mid_box {
        flex: 1;
        .title {
          color: #101010;
          font-weight: 600;
          font-size: 28px;
        }
        .detail {
          margin-top: 10px;
          color: #9a9a9a;
          font-size: 24px;
        }
        .red_tip {
          margin-left: 10px;
          font-size: 24px;
          color: #ee2e2e;
        }
      }
      .right_menu {
        margin-left: 20px;
        width: 110px;
        .red_btn {
          display: inline-block;
          margin-left: 0;
          width: 110px;
          padding: 8px 0px;
          border-radius: 10px;
          background-color: #ee2e2e;
          color: #fff;
          font-size: 24px;
          text-align: center;
        }
        .gray_btn {
          display: inline-block;
          margin-left: 0;
          width: 110px;
          padding: 8px 0px;
          border-radius: 10px;
          background-color: #bbbbbb;
          color: #fff;
          font-size: 24px;
          text-align: center;
        }
      }
    }
  }
}

.van-popup{
  background: transparent;
}
.popup_body {
  position: relative;
  padding: 40px 80px;
  margin: auto 100px;
  width: calc(100vw - 380px);
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(213,62,36,0.3);
  .close_icon {
    position: absolute;
    font-size: 32px;
    top: 25px;
    right: 25px;
  }
  .top_box {
    text-align: center;
    font-size: 26px;
    color: #333;
    .tip1 {
      margin-top: 10px;
      color: #ee2e2e;
    }
    .tip2 {
      margin: 10px 0;
      font-weight: 600;
    }
  }
  .bot_box {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode {
      width: 240px;
      height: 240px;
      display: block;
    }
    .bot_tip {
      margin-top: 20px;
      text-align: center;
      font-size: 26px;
      color: #333;
    }
  }

}

</style>
